import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'
import { useNecessary } from '../../../hooks/necessary'
import { buildings } from '../../../models/buildings'
import useApi from '../../../requestProvider/apiHandler'
import { ReactComponent as Close } from '../../images/airdrop/close.svg'
import { ReactComponent as Coin } from '../../images/homepage/coin.svg'
import { ReactComponent as Cartoon } from '../../images/mycity/Cartoon.svg'
import { ReactComponent as Court } from '../../images/mycity/court.svg'
import { ReactComponent as DogPark } from '../../images/mycity/Dogpark.svg'
import { ReactComponent as FireStation } from '../../images/mycity/Firestation.svg'
import { ReactComponent as Hospital } from '../../images/mycity/hospital.svg'
import { ReactComponent as Store } from '../../images/mycity/market.svg'
import { ReactComponent as Museum } from '../../images/mycity/museum.svg'
import { ReactComponent as Park } from '../../images/mycity/Park.svg'
import { ReactComponent as Parking } from '../../images/mycity/Parking.svg'
import { ReactComponent as Police } from '../../images/mycity/Policestation.svg'
import { ReactComponent as School } from '../../images/mycity/School.svg'
import { ReactComponent as Tv } from '../../images/mycity/Tv.svg'
import buildState from '../../store/buildState'
import useStore from '../../store/zustand'

interface SwipeUpPanelProps {
  height?: string;
}

const SwipeUpPanel: React.FC<SwipeUpPanelProps> = ({ height = '85vh' }) => {
  const [open, setOpen] = useState(false);
  const [visibleHeight, setVisibleHeight] = useState('56vh');
  const panelRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const heightValue = parseFloat(height);

  const [{ y }, api] = useSpring(() => ({ y: heightValue - parseFloat(visibleHeight) }));

  useEffect(() => {
    api.start({ y: open ? 0 : heightValue - parseFloat(visibleHeight) });
  }, [open, heightValue, visibleHeight, api]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight <= 670) {
        setVisibleHeight('45vh');
      } else if (window.innerHeight <= 770) {
        setVisibleHeight('51vh')
      } else {
        setVisibleHeight('54vh');
      }
    };


    handleResize();

    
    window.addEventListener('resize', handleResize);

    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const apii = useApi();
  const { buildings } = buildState();
  const { getBuildings, getData } = useNecessary();
  const { balance, coin_per_hour, updateBalance } = useStore();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<buildings>();
  const {t} = useTranslation()

  const buyBuilding = useCallback(async () => {
    setOpenModal(false);
    const res = await apii({
      method: 'POST',
      url: `/building/buy?building_id=${modalInfo?.id}`,
    });
    getData();
    getBuildings();
  }, [apii, modalInfo?.id, getData, getBuildings]);


  const GetIcon = memo(({ iconType }: { iconType: string }) => {
    switch (iconType) {
      case 'Hospital':
        return <Hospital className='w-[70px] h-[70px]' />;
      case 'Police Department':
        return <Police className='w-[70px] h-[70px] rounded-lg' />;
      case 'Fire Station':
        return <FireStation className='w-[70px] h-[70px] rounded-lg' />;
      case 'Park':
        return <Park className='w-[70px] h-[70px] rounded-lg' />;
      case 'Court':
        return <Court className='w-[70px] h-[70px] rounded-lg' />;
      case 'Dog Park':
        return <DogPark className='w-[70px] h-[70px] rounded-lg' />;
      case 'TV station':
        return <Tv className='w-[70px] h-[70px] rounded-lg' />;
      case 'School':
        return <School className='w-[70px] h-[70px] rounded-lg' />;
      case 'General Store':
        return <Store className='w-[70px] h-[70px] rounded-lg' />;
      case 'Shopping Center':
        return <Cartoon className='w-[70px] h-[70px] rounded-lg' />;
      case 'Museum':
        return <Museum className='w-[70px] h-[70px] rounded-lg' />;
      default:
        return <Parking className='w-[70px] h-[70px]' />;
    }
  });

  useEffect(() => {
    const coinsPerSecond = coin_per_hour / 3600;

    const intervalId = setInterval(() => {
      updateBalance(balance + coinsPerSecond);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [coin_per_hour, balance, updateBalance]);

  const BalanceDisplay = memo(({ balance }: { balance: number }) => {
    return <p className='mt-3'>{Math.round(balance)}</p>;
  });

  return (
    <>
      <animated.div
        ref={panelRef}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height,
          backgroundColor: '#797979',
          borderRadius: '30px 30px 0px 0px',
          transform: y.to((y) => `translateY(${y}vh)`),
          touchAction: 'none',
        }}
      >
        <div
          ref={handleRef}
          className='h-[55px] items-center flex justify-center text-white text-2xl font-bold bg-myColors-100 rounded-t-[30px] gap-1'
          onClick={() => setOpen(!open)}
        >
          <div
            className='absolute top-[6px] left-[42%] right-0 h-[3px] w-[70px] bg-myColors-300 rounded-full'
          ></div>
          <div className='relative'>
            <Coin className='w-[35px] h-[45px] mt-4' />
          </div>
          <BalanceDisplay balance={balance} />
        </div>

        <div className={`grid grid-cols-2 gap-6 justify-items-center ${open ? 'pb-[150px]' : 'pb-[370px]'} w-full px-2 overflow-y-scroll overflow-x-hidden h-[calc(85vh-55px)] pt-1`}>
          {buildings.map((building, index) => (
            <div key={index} className='bg-myColors-350 flex flex-col rounded-lg h-[100px] w-[180px]'>
              <div className='flex gap-1 px-2 my-2'>
                <GetIcon iconType={building.image} />
                <div className='flex flex-col'>
                  <p className='text-[10px] mt-2 font-bold'>{building.name}</p>
                  <p className='text-[7px] opacity-80 mt-2'>{t('cityEarnPerTime')}: <br /> {building.per_hour} GT</p>
                </div>
              </div>
              <div className='flex w-full justify-between text-sm'>
                <p className='p-1 bg-myColors-100 w-[60px] rounded-l-lg text-center'>lvl {building.lvl}</p>
                <p
                  className='text-center bg-myColors-400 w-full p-1 rounded-r-lg'
                  onClick={() => {
                    if (building.lvl < 12) {
                      setModalInfo(building);
                      setOpenModal(true);
                    }
                  }}
                >
                  {building.price}GT
                </p>
              </div>
            </div>
          ))}
        </div>
      </animated.div>

      {openModal && (
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 text-white transition-opacity duration-300 ease-in-out`}>
          <div
            className={`bg-myColors-800 border border-myColors-850 rounded-[35px] py-2 pb-4 px-4 md:max-w-md mx-2 z-60 transform w-[350px] transition-transform duration-300 ease-in-out`}
          >
            <div className='flex justify-end'>
              <Close
                className='w-[25px]'
                onClick={() => {
                  setModalInfo(undefined);
                  setOpenModal(false);
                }}
              />
            </div>
            <p className='text-center font-bold text-xl'>{t('buyBuilding')}</p>
            <p className='text-center mt-5'>
              {t("?forbuy")} <span className='text-myColors-250 font-bold'>{`${modalInfo?.price}GT`}</span> ?
            </p>
            <div className='flex gap-5 justify-center items-center mt-10'>
              <button
                className='p-2 px-4 bg-myColors-500 font-bold w-[130px] rounded-3xl'
                style={{ boxShadow: '0 4px 25px rgba(247, 174, 59, 1)' }}
                onClick={buyBuilding}
              >
                {t("buy")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(SwipeUpPanel);
