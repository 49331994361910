import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { abbreviateNumber } from '../../hooks/convertNumber'
import { useTelegram } from '../../hooks/useTelegram'
import useApi from '../../requestProvider/apiHandler'
import { ReactComponent as Indonesia } from '../images/flags/indonesia.svg'
import { ReactComponent as Russian } from '../images/flags/russian.svg'
import { ReactComponent as United } from '../images/flags/united.svg'
import { ReactComponent as Settings } from '../images/header/settings.svg'
import { ReactComponent as Coin } from '../images/homepage/coin.svg'
import { ReactComponent as Logo } from '../images/logo.svg'
import useStore from '../store/zustand'
const Header = () => {
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const changeLanguageAPI = async(culture: string) => {
    const res = await api({
      method: 'PATCH',
      url: `/identity/change_language?culture=${culture}`
    })
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    changeLanguageAPI(lang)
  }

  const currentLanguage = i18n.language;

  const getFlagComponent = () => {
    if (currentLanguage === 'ru') {
      return <Russian className='w-[25px]' />;
    } else if (currentLanguage === 'en') {
      return <United className='w-[25px]' />;
    } else {
      return <Indonesia className='w-[25px]' />;
    }
  };
  
  const CurrentFlag = getFlagComponent();
  

  const {user} = useTelegram()
  const {coin_per_hour} = useStore()
  const api = useApi()

  

  return (
    <>
      <div
        className={`relative h-[100px] w-full bg-black opacity-55`}
      >
      </div>
      <div className='absolute top-6 w-full px-5'>
        <div className='flex justify-between items-center'>
          <div className='flex gap-1 items-center'>
            <div>
              <Logo className='w-[35px] h-[35px]' />
            </div>

            <div className='flex flex-col text-[12px] text-white'>
              <p>@{user}</p>
              <p className='opacity-45 ml-[2px]'>{t('level')} 1</p>
            </div>
          </div>

          <div className='flex gap-3 items-center rounded-2xl bg-black bg-opacity-[40%]'>
            <div
              className='relative rounded-2xl bg-black w-[50px] h-[45px] flex justify-center items-center bg-opacity-55 cursor-pointer'
              onClick={toggleDropdown}
            >
              {CurrentFlag}
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    className='absolute top-full right-0 w-[50px] mt-1 bg-black bg-opacity-85 text-black rounded-lg shadow-lg z-[99]'
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className='flex justify-center flex-col items-center pb-1'>
                      <United className='w-[25px]' onClick={() => changeLanguage('en')} />
                      <Russian className='w-[25px]' onClick={() => changeLanguage('ru')} />
                      <Indonesia className='w-[25px]' onClick={() => changeLanguage('id')}/>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className='flex flex-col text-white gap-1'>
              <p className='text-[8px] text-center opacity-45 tracking-wide leading-none' dangerouslySetInnerHTML={{ __html: t('earnperhour') }}>
                
              </p>
              <div className='text-center text-[12px] flex gap-1 items-center justify-center'>
                <Coin className='w-5 h-5' />
                <p>{abbreviateNumber(coin_per_hour)}</p>
              </div>
            </div>

            <div className='rounded-2xl bg-black w-[50px] h-[45px] flex justify-center items-center bg-opacity-55'>
              <Settings className='w-[20px]' onClick={() => navigate('/Settings')} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
