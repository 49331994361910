import { initUtils } from '@tma.js/sdk'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { formatDateTime } from '../../../hooks/convertDate'
import tap from '../../images/homepage/tapformap.png'
import './map.css'
function UpdateMapCenter({ position }: { position: [number, number] }) {
  const map = useMap();
  const [isInitialZoom, setIsInitialZoom] = useState(true);

  useEffect(() => {
    if (position && isInitialZoom) {
      map.setView(position, 13); 
      setIsInitialZoom(false);
    }
  }, [position, map, isInitialZoom]);

  return null;
}

function getDistance(coord1: [number, number], coord2: [number, number]): number {
  const R = 6371000; 
  const φ1 = coord1[0] * (Math.PI / 180);
  const φ2 = coord2[0] * (Math.PI / 180);
  const Δφ = (coord2[0] - coord1[0]) * (Math.PI / 180);
  const Δλ = (coord2[1] - coord1[1]) * (Math.PI / 180);

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

interface GameData {
  place_count: number;
  geolocation_x: number;
  geolocation_y: number
  total: number;
  game_reward: number;
  id: string;
  start_at:string
}

interface Props {
  gameData: GameData[];
  handleClick: (closeid: string) => void;
  setError: any;
  open: boolean
}

interface Marker {
  position: [number, number];
  showClick: boolean;
  placeCount: number;
  total: number;
  game_reward: number;
  id: string;
  start_at:string
}


function Map({ gameData, handleClick, setError, open}: Props) {
  const [position, setPosition] = useState<[number, number] | null>(null);
  const [markers, setMarkers] = useState<{ position: [number, number]; showClick: boolean; placeCount: number; total: number; id:string, start_at:string, game_reward:number }[]>([]);
  const { t } = useTranslation();
  const [closeId, setCloseId] = useState<string>('')


  
  useEffect(() => {
    const handleSuccess = (pos: GeolocationPosition) => {
      const { latitude, longitude } = pos.coords;
      const newPosition: [number, number] = [latitude, longitude];
      setPosition(newPosition);

      const newMarkers = gameData
        .map((data) => {
            const markerPosition: [number, number] = [data.geolocation_x, data.geolocation_y];
            const distanceToMarker = getDistance(newPosition, markerPosition);
            return {
              position: markerPosition,
              showClick: distanceToMarker <= 100,
              placeCount: data.place_count,
              total: data.total,
              game_reward: data.game_reward,
              id: data.id,
              start_at: data.start_at
            };
        })
        .filter((marker): marker is Marker => marker !== null);     

  setMarkers(newMarkers)
};



    const handleError = (err: GeolocationPositionError) => {
      console.error(`Geolocation error: ${err.message}`);
      alert('Geolocation is not supported or permission was denied.');
    };

    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(handleSuccess, handleError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });

      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      console.error('Geolocation is not supported by your browser.');
    }
  }, [gameData]);

  useEffect( () => {
    const closestMarker = markers.find(marker => marker.showClick === true);
    setCloseId(closestMarker?.id || '');
  }, [markers])

  const createCustomIcon = (text: string) => {
    return L.divIcon({
      html: `
        <div class="custom-marker">
          <div class="marker-icon"></div>
          <div class="marker-text text-myColors-250">${text}</div>
        </div>`,
      className: '',
    });
  };

  const anyMarkerShowClick = markers.some((marker) => marker.showClick);
  const foundGame = Array.isArray(gameData) ? gameData.find(data => data.id === closeId) : null;
  const anyGameRewardNegative = foundGame ? foundGame.game_reward === -1 : false;

  const errorSnackbar = () => {
    setError(t('snackbarError'))
    setTimeout(() => {
      setError('')
    }, 2000);
  }

  const utils = initUtils()

  return (
    <div className="App relative">
      <div className="w-full flex justify-center">
        <MapContainer
          center={position || [0, 0]}
          zoom={13}
          style={{ height: '700px', width: '100%', margin: '0 auto' }}
          attributionControl={false}
          zoomControl={false}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://carto.com/">CartoDB</a> contributors'
          />
          {position && (
            <>
              <UpdateMapCenter position={position} />
              <Marker 
                position={position}
                icon={L.divIcon({ className: 'user-marker' })} 
              >

              </Marker>
              {markers.map((marker, index) => (
                <Marker 
                  key={index} 
                  position={marker.position} 
                  icon={createCustomIcon(`${marker.placeCount}/${marker.total}`)}
                >
                  <Popup>
                    <p className='text-myColors-300'>{formatDateTime(marker.start_at)}</p>
                    <p className='flex gap-1 text-myColors-300'>{t('adminReward')} <nav className='text-myColors-250 font-bold'>{marker.game_reward === -1 ? t("claimedReward") : marker.game_reward}</nav></p>
                    <button className='bg-myColors-250 text-white font-bold px-5 p-2 rounded-xl' onClick={() => utils.openLink(`https://www.google.com/maps?q=${marker.position[0]},${marker.position[1]}`)}>{t('checkGooleMap')}</button>
                  </Popup>
                </Marker>
              ))}
            </>
          )}
        </MapContainer>

        <div className={`fixed text-white z-[999] ${open ? 'bottom-[105px]' : 'bottom-[265px]'}`}>
          {anyGameRewardNegative ? (
            <img src={tap} className="w-[65px] h-[65px] opacity-35" onClick={() => errorSnackbar()} />
          ) : anyMarkerShowClick ? (
            <img src={tap} className="w-[65px] h-[65px]" onClick={() => handleClick(closeId)} />
          ) : (
            <img src={tap} className="w-[65px] h-[65px] opacity-35" />
          )}
        </div>
      </div>
      <p className="text-white text-[15px] opacity-35">{t("physicalTapDescr")}</p>
    </div>
  );
}

export default Map;
