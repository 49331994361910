import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react'
import { useEffect } from 'react'
import { ErrorProvider, useError } from "./requestProvider/errorContext"
import ErrorSnackbar from './requestProvider/errorSnackbar'
import Router from './router/router'
function App() {

  interface TelegramWebApp {
    close: () => void;
      expand: () => void
  }
  
  const tg: TelegramWebApp = (window as any).Telegram.WebApp;
  useEffect(() => {
          if (tg && tg.expand) {
              tg.expand();
          }
        }, []);

  return (
    <TonConnectUIProvider
    manifestUrl="https://spa.governorsgame.com/tonconnect-manifest.json"
    uiPreferences={{theme: THEME.DARK}}
    actionsConfiguration={{
      twaReturnUrl: 'https://t.me/Governorsgame_bot'
    }}>
      <ErrorProvider>
          <AppContext/>
      </ErrorProvider>
    </TonConnectUIProvider> 
  );
}

const AppContext = () => {
  const { error, setError } = useError();
  return (
    <>
     <Router/>
     <ErrorSnackbar error={error} onClose={() => setError('')} />
    </>
  )
}

export default App;
