import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Coin } from '../components/images/homepage/coin.svg'
import { ReactComponent as Home } from '../components/images/homepage/home.svg'
import { ReactComponent as Location } from '../components/images/homepage/location.svg'
import useStore from '../components/store/zustand'
import '../components/ui/background.css'
import OnlineGame from '../components/ui/homepage/onlineGame'
import PhysicalGame from '../components/ui/homepage/physicalGame'
const Homepage = () => {
	const [mode, setMode] = useState<string>('online')
	const {t} = useTranslation()
	
	const SwitchMode = useCallback(() => {
    switch (mode) {
      case 'online': return <OnlineGame />;
      case 'offline': return <PhysicalGame />;
      default: return null;
    }
  }, [mode]);
	
	const {balance, coin_per_hour, updateBalance} = useStore()

	useEffect(() => {
    const coinsPerSecond = coin_per_hour / 3600;

    const intervalId = setInterval(() => {
      updateBalance(balance + coinsPerSecond);
    }, 1000); 

    return () => clearInterval(intervalId);
  }, [coin_per_hour, balance, updateBalance]);


	return (
		<>
		<div className={`fixed w-full h-[85vh] bg-myColors-100 rounded-t-[30px] bottom-0 `}>
			
		<div className='h-[50px] items-center flex justify-center text-white text-2xl font-bold bg-myColors-50 rounded-t-[30px] gap-2'>
			<Coin className='w-[35px]'/>
			<p className='mb-1'>{Math.round(balance)}</p>
		</div>
		
		<div className='h-full'>

			<div className='flex w-full justify-center bg-myColor-50 mt-5 px-5 text-white gap-3'>

				<div className={`flex flex-col justify-center items-center text-center ${mode === 'online' ? 'bg-myColors-150' : 'bg-myColors-200'} h-[50px] p-1 w-[170px] rounded-[10px]`} onClick={() => setMode('online')}>
					<Home className='w-[20px]'/>
					<p className='text-sm tracking-tight font-bold'>{t('online')}</p>
				</div>

				<div className={`flex flex-col justify-center items-center  text-center ${mode === 'offline' ? 'bg-myColors-150' : 'bg-myColors-200'} p-1 w-[170px] rounded-[10px] h-[50px]`} onClick={() => setMode('offline')}>
					<Location className='w-[17px]' />
					<p className='text-sm tracking-tight font-bold'>{t('physical')}</p>
				</div>

			</div>

			
			{SwitchMode()}

		</div>
		</div>
		</>
	)
}

export default Homepage