import SwipeUpPanel from './swipeUpPanel'



const PhysicalGame = () => {

  return (
    <>
      <SwipeUpPanel/>
    </>
  );
};

export default PhysicalGame;
