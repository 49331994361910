import { useCallback } from 'react'
import { ReactComponent as Court } from '../components/images/myCityBg/court.svg'
import { ReactComponent as FireStation } from '../components/images/myCityBg/firestation.svg'
import { ReactComponent as General } from '../components/images/myCityBg/general.svg'
import { ReactComponent as Museum } from '../components/images/myCityBg/museum.svg'
import { ReactComponent as Park } from '../components/images/myCityBg/park.svg'
import { ReactComponent as Playground } from '../components/images/myCityBg/playground.svg'
import { ReactComponent as Policestation } from '../components/images/myCityBg/policestation.svg'
import { ReactComponent as School } from '../components/images/myCityBg/school.svg'
import { ReactComponent as Supermarket } from '../components/images/myCityBg/supermarket.svg'
import { ReactComponent as Tvstation } from '../components/images/myCityBg/tvstation.svg'
import { ReactComponent as Warehouse } from '../components/images/myCityBg/warehouse.svg'
import buildState from '../components/store/buildState'
import SwipeUpPanel from '../components/ui/citypage/swipeUpPanel'

const Citypage = () => {
	const {buildings} = buildState()

	interface icon {
		icon: string
	}

	const GetIcon = useCallback(({ icon }: icon) => {
		switch (icon) {
			case 'Fire Station': 
				return (
					<div className='relative flex justify-center items-center'>
						<FireStation className='w-[125px] absolute top-[-125px] left-[55px]' />
					</div>
				);
			case '123123': 
				return (
						<Warehouse className='w-[100px] absolute top-[-185px]' />
				);
			case 'School': 
			  return (
					<School className='w-[110px] absolute top-[-210px] right-0'/>
				)
			case 'Park': 
				return (
					<div className='relative flex justify-center items-center'>
						<Park  className='absolute w-[75px] h-[75px] top-[-9px] left-[230px] custom-xl:left-[260px] custom-md:left-[250px]'/>
					</div>
				);
			case 'Court':
				return (
					<Court className='w-[150px] h-[150px] absolute top-[130px] left-[-35px]'/>
				)

			case 'General Store': 
				return (
					<General  className='w-[90px] h-[90px] absolute top-[57px] right-16'/>
				)
			
			case 'Museum': return (
				<Museum className='w-[110px] h-[110px] absolute top-[68px] left-[90px] z-[999]'/>
			)
			case  'Police Department': return (
				<Policestation className='top-[165px] custom-lg:top-[175px] h-[90px] w-[135px] absolute custom-lg:left-[85px] custom-xl:left-[110px] custom-xl:top-[195px] custom-md:left-[95px] custom-md:top-[185px] custom-sm:left-[80px]'/>
			)
			case 'Dog Park': return (
				<Playground className='w-[70px] h-[70px] absolute top-[25px] right-4'/>
			)
			case 'TV station': return (
				<Tvstation className='w-[90px] h-[90px] left-[155px] absolute top-5'/>
			)
			case 'Shopping Center': return (
				<Supermarket className='absolute w-[120px] top-[-30px] right-[110px]'/>
			)
			default: 
				return null;
		}
	}, [buildings]);

	return (
		<>
			<div className='relative'>
			<div className='backgroundCity mt-[100px]'>
				{buildings.map((building) => (
					building.lvl >= 1 && (
						<GetIcon icon={building.image}/>
					)
				))}
			</div>
			</div>

		<div className={`fixed w-full h-[35vh] bg-myColors-100 rounded-t-[30px] bottom-0`}>
			<div className='h-full'>
				<div className='flex w-full justify-center px-5 text-white gap-3'>
					<SwipeUpPanel/>
				</div>
			</div>
		</div>
		</>
	)
}

export default Citypage